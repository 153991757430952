import { DataLayer } from "./DataLayer";

declare global {
    interface IOri {
        dataLayer?: DataLayer;
    }
}

// for legacy js code
if (window.ori) {
    ori.dataLayer = DataLayer;
}
