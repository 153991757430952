declare global {
    interface Window {
        // eslint-disable-line @typescript-eslint/naming-convention
        dataLayer: object[];
    }
}

/**
 * Provides access to the GTM's dataLayer array.
 * TODO: This should be dropped & replaced with "standard" analytics provider functionality.
 */
export class DataLayer {
    /*
     * Adds object to the GTM global dataLayer array. If dataLayer doesn't exist, it will be created.
     * @param obj Object will be added to dataLayer
     */

    public static push(obj: Object): void {
        if (typeof window.dataLayer === "undefined") {
            // eslint-disable-line
            window.dataLayer = [];
        }

        window.dataLayer.push(obj);
    }
}
